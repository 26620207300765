<template>
  <TTabs :tabs="tabs" :activeTab="$route.query.tab" @change-tab="changeTab">
    <template #settings>
      <Setting />
    </template>
    <template #pallet_type>
      <PalletType />
    </template>
    <template #shipment_method>
      <ShipmentMethod />
    </template>
    <template #shelves>
      <Shelve/>
    </template>
    <template #area>
      <Area/>
    </template>
    <template #print>
      <Print/>
    </template>
  </TTabs>
</template>
<script>
import { mapGetters } from "vuex";
import Area from './components/Area.vue';
import Setting from './components/Setting.vue';
import PalletType from "./components/PalletType.vue";
import Shelve from './components/Shelve.vue';
import ShipmentMethod from "./components/ShipmentMethod.vue";
import Print from "./components/Print.vue";

export default {
  components: {
    PalletType,
    ShipmentMethod,
    Shelve,
    Area,
    Setting,
    Print,
  },
  data() {
    return {
      tabs: [
        {
          key: "settings",
          name: "General settings",
          icon: "cis-library-books",
        },
        { key: "pallet_type", name: "Pallet type", icon: "cis-truck" },
        { key: "area", name: "Area", icon: "cis-truck" },
        { key: "shipment_method", name: "Shipment method", icon: "cis-truck" },
        { key: "shelves", name: "Shelves", icon: "cis-note" },
        { key: "print", name: "Send print data", icon: "cis-print" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      pallet_types: "warehouse.pallet_types.detail",
    }),
    id() {
      return this.$route.params.id;
    },
  },
  methods: {
    changeTab(tab) {
      this.$router.push({ query: { tab: tab } });
    },
  },
};
</script>
