<template>
  <div>
    <TTableAdvance
      :items="list"
      :fields="fields"
      store="warehouse.pallet_types"
      resource=""
      noFilter
      creatable
      deletable
      @click-create="showModalCreate = true"
    >
      <template #append-actions>
        <CCol class="px-1 text-center" col="12">
          <TButtonEdit @click="showModalEdit = true" />
        </CCol>
      </template>
      <template #max_height="{ item }">
        <td>
          <TMessageNumber :value="item.max_height" />
        </td>
      </template>
      <template #max_length="{ item }">
        <td>
          <TMessageNumber :value="item.max_length" />
        </td>
      </template>
      <template #max_weight="{ item }">
        <td>
          <TMessageNumber :value="item.max_weight" />
        </td>
      </template>
      <template #max_width="{ item }">
        <td>
          <TMessageNumber :value="item.max_width" />
        </td>
      </template>
      <template #weight="{ item }">
        <td>
          <TMessageNumber :value="item.weight" />
        </td>
      </template>
    </TTableAdvance>
    <PalletTypeModal :show.sync="showModalCreate" />
    <PalletTypeModal :show.sync="showModalEdit" :palletType="detail" />
  </div>
</template>

<script>
import PalletTypeModal from "./PalletTypeModal.vue";
import { mapGetters } from "vuex";
export default {
  components: { PalletTypeModal },
  data() {
    return {
      fields: [
        { key: "_", _style: "width: 40px; min-width: 40px" },
        { key: "id", label: "Id" },
        { key: "weight", label: "Weight" },
        { key: "max_height", label: "Max height" },
        { key: "max_length", label: "Max length" },
        { key: "max_weight", label: "Max weight" },
        { key: "max_width", label: "Max width" },
      ],
      showModalCreate: false,
      showModalEdit: false,
    };
  },
  created() {
    this.$store.dispatch("warehouse.pallet_types.fetch.if-first-time");
  },
  computed: {
    ...mapGetters({
      list: "warehouse.pallet_types.list",
      detail: "warehouse.pallet_types.detail",
    }),
  },
};
</script>
