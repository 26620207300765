<template>
  <TTableAdvance
    :items="list"
    :fields="fields"
    store="warehouse.shipment_methods"
    resource=""
    noFilter
  >
  </TTableAdvance>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      fields: [
        { key: "id", label: "Id" },
        { key: "name", label: "Name" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      list: "warehouse.areas.list",
    }),
  },
};
</script>
