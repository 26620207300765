<template>
  <CCard>
    <CCardBody>
      <div class="d-flex align-items-center mb-2">
        <TMessage content="Printer server IP" class="" bold />
        <CBadge class="ml-1" :color="isConnected ? 'success' : 'danger'">{{
          isConnected ? $t("Connected") : $t("Not connected")
        }}</CBadge>
      </div>
      <CRow class="d-flex">
        <CCol col="8" sm="10">
          <TInputText
            class="w-100"
            :value.sync="url"
            @update:value="url = $event"
          />
        </CCol>
        <CCol col="4" sm="2">
          <TButton
            content="Connect"
            class="w-100"
            icon="cil-power-standby"
            :options="{ disabled: loading }"
            @click="connectServer()"
          />
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import printer_url from "@/core/services/tomoni/printer_url.local";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      loading: false,
      url: "",
    };
  },
  computed: {
    ...mapGetters({
      isConnected: "printer.isConnected",
    }),
    printer_url() {
      return printer_url?.get();
    },
  },
  watch: {
    printer_url: {
      immediate: true,
      handler(value) {
        this.url = value || "ws://127.0.0.1:2303";
      },
    },
  },
  methods: {
    connectServer() {
      this.loading = true;
      this.$store.dispatch("printer.connect", this.url).finally(() => {
        this.loading = false;
      });
    },
  },
};
</script>
