<template>
  <div>
    <TTableAdvance
      :items="list"
      :fields="fields"
      store="warehouse.shelves"
      resource=""
      creatable
      deletable
      noFilter
      @click-clear-filter="clearFilter"
      @click-create="showModalCreate = true"
    >
      <template #append-actions>
        <CCol class="px-1 text-center" col="12">
          <TButtonEdit @click="showModalEdit = true" />
        </CCol>
      </template>
    </TTableAdvance>
    <ShelveModal :show.sync="showModalCreate" />
    <ShelveModal :show.sync="showModalEdit" :shelve="detail" />
  </div>
</template>

<script>
import ShelveModal from "./ShelveModal.vue";
import { mapGetters } from "vuex";
export default {
  components: { ShelveModal },
  data() {
    return {
      fields: [
        { key: "_", _style: "width: 40px; min-width: 40px" },
        { key: "id", label: "Id" },
        { key: "name", label: "Name" },
        { key: "floor", label: "Floor" },
        { key: "column", label: "Column" },
        { key: "row", label: "Row" },
      ],
      showModalCreate: false,
      showModalEdit: false,
      filter: {},
    };
  },
  created() {
    this.$store.dispatch("warehouse.shelves.fetch.if-first-time");
  },
  computed: {
    ...mapGetters({
      list: "warehouse.shelves.list",
      detail: "warehouse.shelves.detail",
    }),
  },
  methods: {
    filterChange() {
      const filter = this.lodash.mapKeys(this.filter, function (value, key) {
        return `filter[${key}]`;
      });
      const filterFields = this.lodash.pickBy(filter);
      this.$store.dispatch("warehouse.shelves.apply-query", filterFields);
    },
    clearFilter() {
      this.dateRange = {
        start: null,
        end: null,
      };
      this.filter = {};
      this.filterChange();
    },
  },
};
</script>
